import React from 'react';
import SliderComponent from './Slider/Slider';
import content from './Slider/assets/content';
import './homevideo.css';
import video from './sntsa.mp4';

const Home = () => {

    return(
            <div className="container">
                <h4>Inicio</h4>
                <video
                    className="video"
                    controls
                    controlsList="nodownload noremoteplayback"
                    autoPlay muted
                >
                    <source src={video} type="video/mp4"/>
                </video>
                <SliderComponent content={ content } />
            </div>
    );
}

export default Home;