import React from 'react';

const Jubilacion = () => {
    return (
        <div className="container">
            <h4>Jubilación</h4>
            <div className="card border-info mb-3">
                <h5 className="card-header">Requisitos 2024 - 2025</h5>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        La edad mínima para jubilarse en <strong>hombres es de 58 años de edad y 30 años o más de servicio</strong>.<br/>
                        La edad mínima para jubilarse en <strong>mujeres es de 56 años de edad y 28 años o más de servicio</strong>
                    </li>
                    <li className="list-group-item">
                        Para ser candidato a una <strong>pensión por edad y tiempo de servicio</strong>, tener <strong>60 años de edad o más y 15 años o más de servicio</strong>
                    </li>
                    <li className="list-group-item">
                        Para ser candidato a una <strong>pensión por cesantía en edad avanzada</strong>, tener <strong>65 años de edad o más y 10 años o más de servicio</strong>
                    </li>
                </ul>
                <h5 className="card-header">Documentación necesaria para solicitar Licencia por Jubilación</h5>
                <ul className="list-group list-group-flush">
                    {/*<li className="list-group-item">Original y copia de las cartas de no adeudo de las <strong>Dirección de Recursos Financieros y Subdirección de Recursos Materiales</strong>, (solicitar este trámite en su servicio con su jefe o delegado según corresponda)</li>*/}
                    <li className="list-group-item">Original de credencial del HGM</li>
                    <li className="list-group-item">2 copias de último talón de pago</li>
                    <li className="list-group-item">1 copia de cada uno de los talones de pago del periodo de 1980 a 1993</li>
                    {/*<li className="list-group-item">Estado de cuenta de PENSIONISSSTE si se encuentra en el <strong>Régimen de Cuentas Individuales</strong></li>*/}
                </ul>
                <br/>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <strong>La licencia por jubilación se autoriza los días 1 y 16 del mes a elegir.</strong>
                    </li>
                    <li className="list-group-item">
                        Deberá presentar la documentación completa en el Departamento de Servicios y Prestaciones <strong>1 mes antes del inicio de la licencia prejubilatoria</strong> en un sobre amarillo.
                    </li>
                    <li className="list-group-item">
                        Todos los documentos que se entreguen deberán ser legibles y encontrarse en buen estado sin mutilaciones, tachaduras, enmendaduras, deterioro o alteraciones
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Jubilacion;