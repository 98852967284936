import representacion from './img64/representacion';
import accidenteTrabajo from './img64/accidenteTrabajo';
//import apoyoLentes from './img64/apoyoLentes';
//import cedulasProductividad from './img64/cedulasProductividad';

const content = [
    {
        title: 'Accidente de Trabajo',
        description: 'Accidente de Trabajo',
        to: '/accidente-trabajo',
        button: 'Ir a',
        image: accidenteTrabajo
    },
    {
        title: 'CGT',
        description: 'CGT',
        to: '',
        button: 'Ver',
        image: representacion
    },
];

export default content;