import React, { useEffect, useState, Fragment } from 'react';
import Titulo from './Titulo';
import { Route, Switch, Link } from 'react-router-dom';
import Home from '../Home';
import Footer from '../Footer/Footer';
import RamaAdministrativa from '../CatalogoPuestos/RamaAdministrativa';
import RamaMedica from '../CatalogoPuestos/RamaMedica';
import CredencialesDeportivo from '../Deportivo/CredencialesDeportivo';
import Palapas from '../Deportivo/Palapas';
import Prestamo from '../Prestamo/Prestamo';
import Lentes from '../Lentes';
import FAD from '../FAD/FAD';
import CLIDDA from '../CLIDDA/CLIDDA';
import Oficializacion from '../Oficializacion';
import Tesis from '../Tesis';
import LicenciaHumanitaria from '../LicenciaHumanitaria';
import Jubilacion from '../Jubilacion';
import Normatividad from '../Normatividad';
import EvaluacionMensual from '../EvaluacionMensual/EvaluacionMensual';

import AccidenteTrabajo from '../Accidente Trabajo/AccidenteTrabajo';
import Becas from '../Temporales/Becas/Becas';
import EscalafonConvocatorias from '../Temporales/Escalafon/convocatorias/Escalafon';
import EscalafonResultados from '../Temporales/Escalafon/resultados/Escalafon';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import grey from '@material-ui/core/colors/grey';
import logo from '../img/Logo_SNTSA.gif';
import Menu from './Menu.json';
import SubMenu from './SubMenu';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: grey[200],
    textAlign: "center"
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'rgb(14, 130, 219)',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: grey[200],
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Header = () =>  {
  const classes = useStyles();
  const [inicio, setInicio] = useState(false);
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    //obtiene menú que se debe mostrar
    const menuActivo = Menu.filter(item => item.activo === true);
    //ordena alfabeticamente
    const menuActivoOrderer = menuActivo.sort((a,b) => (a.nombre > b.nombre ? 1 : -1));
    //cambia menú inicio a la posición 0
    const opcInicio = menuActivoOrderer.find(item => item.nombre === 'Inicio');
    const menuSinInicio = menuActivoOrderer.filter(item => item.nombre !== 'Inicio');

    setMenu([opcInicio, ...menuSinInicio]);
  }, []);

  useEffect(() => {
    if(inicio === false & window.screen.width > 637) {
      setOpen(true);
      setInicio(true);
    }
  }, [inicio]);

  const handleDrawerOpen = open => {
    setOpen(open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
              aria-label="open drawer"
              onClick={() => handleDrawerOpen(!open)}
              edge="start"
              className={clsx(classes.menuButton, open )}
          >
              <MenuIcon />
          </IconButton>
          <div className="col-2">
            <img src={ logo } alt="Logo SNTSA" width="60px" />
          </div>
          { (!open || window.screen.width > 637) ? <Titulo /> : '' }
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{  paper: classes.drawerPaper,  }}
      >
        <div className={classes.drawerHeader}>
              <Link to="/evaluacionmensual" onClick={() => handleDrawerOpen(false)} style={{ height: '20px', width: '40px', marginRight: '90%'}}>
                {'°'}
              </Link>
          {/* Este espacio es par poner algún elemento en la parte superior del menú */}
        </div>
        <List>
          {menu.map((item, index) => (
            (item.to !== null)
            ? <Link to={item.to} key={index} onClick={() => handleDrawerOpen(false)} style={{textDecoration: 'none'}}>
                <ListItem button>
                  <ListItemText primary={<strong>{item.nombre}</strong>} className="text-menu" />
                  <ListItemIcon><KeyboardArrowRight className="btn-icon-menu"/></ListItemIcon>
                </ListItem>
              </Link>
            : <SubMenu key={index} handleDrawerOpen={handleDrawerOpen} item={item}/>
          ))}
        </List>
      </Drawer>
      <main className={clsx(classes.content, {[classes.contentShift]: open,})}>
          <div className={classes.drawerHeader}/>
          <div style={{minHeight: '75vh'}}>
              <Switch>
                <Route exact path="/" component={ Home } />
                <Route exact path="/accidente/trabajo" component={ AccidenteTrabajo } />
                {((menu.find(item => item.id === 2)) && (menu.find(item => item.id === 2).activo)) &&
                  <Route exact path="/becas" component={ Becas } />
                }
                <Route exact path="/deportivo/credencial" component={ CredencialesDeportivo } />
                <Route exact path="/deportivo/palapas" component={ Palapas } />
                {((menu.find(item => item.id === 14)) && (menu.find(item => item.id === 14).activo)) &&
                  <Route exact path="/prestamos" component={ Prestamo } />
                }
                <Route exact path="/previsionsocial/lentes" component={ Lentes } />
                <Route exact path="/previsionsocial/tesis" component={ Tesis } />
                <Route exact path="/fad" component={ FAD } />
                <Route exact path="/clidda" component={ CLIDDA } />
                <Route exact path="/oficializacion" component={ Oficializacion } />
                <Route exact path="/licencia/humanitaria" component={ LicenciaHumanitaria } />
                {((menu.find(item => item.id === 9)) && (menu.find(item => item.id === 9).activo)) &&
                  <Route exact path="/jubilacion" component={ Jubilacion } />
                }
                <Route exact path="/normatividad" component={ Normatividad } />
                <Route exact path="/evaluacionmensual" component={ EvaluacionMensual } />
                <Route exact path="/catpuestos/adminafines" component={ RamaAdministrativa } />
                <Route exact path="/catpuestos/medparamed" component={ RamaMedica } />
                {((menu.find(item => item.id === 6)) && (menu.find(item => item.id === 6).activo)) &&
                  <Fragment>
                    {((menu.find(item => item.id === 6).sub).find(item => item.nombre === ' - Convocatorias').activo) && <Route exact path="/escalafon-convocatorias" component={ EscalafonConvocatorias } />}
                    {((menu.find(item => item.id === 6).sub).find(item => item.nombre === ' - Resultados').activo) && <Route exact path="/escalafon-resultados" component={ EscalafonResultados } />}
                  </Fragment>
                }
              </Switch>
          </div>
          <Footer />
      </main>
    </div>
  );
}

export default Header;